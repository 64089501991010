import React from 'react'
import Layout from '../components/layout'
import Carousel from '../components/carousel/carousel'
import { Link } from 'gatsby';

import carousel1 from '../images/ZepProzess.png'
import certicat from '../images/logoCerticatSmall.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCogs, faClock, faLink, faHandshake, faLightbulb } from '@fortawesome/free-solid-svg-icons'

const IndexPage = () => (
  <Layout>
    <section id="intro">
      <Carousel>
        <div>
          {carousel1}
          <h2>Machen Sie kurzen Prozess mit Ihren Zeugnissen</h2>
          <div className="text">ZEP - Die webbasierende Softwarelösung zum Erstellen Ihrer Arbeitszeugnisse</div>
          <Link to="/zep-kontakt">Kontakt</Link>
        </div>
      </Carousel>
    </section>
    <div className="section-wrapper full-page">
      <div className="certicat">
        <a href="https://certicat.ch"><b>NEU:</b> Arbeitszeugnisse aus der Cloud - <b><img src={certicat} height="35px" alt="certicat.ch" /> </b></a>
      </div>

      <section className="row home-content" style={{ margin: '0' }}>
        <div className="home-section col-md-12 col-lg-6">
          <div className="home-section-title">
            <div className="icon">
              <FontAwesomeIcon icon={faLightbulb} size="3x" />
            </div>
            <div>
              <h1 className="underlined-title">Das Konzept</h1>
              <h5>Individuelle Arbeitszeugnisse generieren</h5>
            </div>
          </div>
          <div>
            <p>
              ZEP generiert Arbeitsbestätigungen, Zwischenzeugnisse und Schlusszeugnisse.
              Dafür verwendet ZEP vorhandene Mitarbeiterdaten, Beurteilungskriterien mit mehr
              als <b>1’800 hinterlegten Textbausteinen pro Sprache </b> und individuell formulierbare Texte. Pro Kompetenzbewertung stehen mehrere Textvarianten zur Verfügung.
            </p>
            <p>
              ZEP steht für Arbeitszeugnisse in Deutsch, Französisch, Italienisch und Englisch
              zur Verfügung. Textbausteine sind in der Gegenwart, Zukunft, weiblichen und männlichen Form vorhanden.
            </p>
            <p>
              ZEP überwacht und steuert den gesamten Ablauf des Prozesses <b>von der Bestellung des Arbeitszeugnisses bis zur Aushändigung.</b>
            </p>
            <p className="home-link">
              <Link to="/zep-info/info">Erfahren Sie mehr</Link>
            </p>
          </div>
        </div>

        <div className="home-section col-md-12 col-lg-6">
          <div className="home-section-title">
            <div className="icon">
              <FontAwesomeIcon icon={faCogs} size="3x" />
            </div>
            <div>
              <h1 className="underlined-title">Der Zeugnisprozess</h1>
              <h5>Optimale Prozessunterstützung</h5>
            </div>
          </div>
          <div>
            <p>
              Das Erstellen von Zwischenzeugnissen, Schlusszeugnissen und Arbeits-bestätigungen wird durch den ZEP Zeugnisprozess entscheidend <b>verkürzt</b>, <b>erleichtert</b> und <b>kostengünstiger.</b>
            </p>
            <p>Der Zeugnisprozess ZEP entlastet das Management und die Personalabteilung.</p>
            <p>ZEP ist durchgängig und vernetzt Mitarbeitende, Vorgesetzte und Personalabteilung ohne Medienbruch.</p>
            <p>ZEP etabliert mit über 1’800 generierbaren und firmenspezifisch erweiterbaren Textbausteinen pro Sprache einen <b>hohen Qualitätsstandard</b> bezüglich <b>Zeugnisformulierungen</b>.</p>
            <p className="home-link"><Link to="/zep-info/prozess">Erfahren Sie mehr</Link></p>
          </div>
        </div>

        <div className="home-section col-md-12 col-lg-6">
          <div className="home-section-title">
            <div className="icon">
              <FontAwesomeIcon icon={faClock} size="3x" />
            </div>
            <div>
              <h1 className="underlined-title">Effizienzsteigerung</h1>
              <h5>Schneller Arbeitszeugnisse erstellen</h5>
            </div>
          </div>
          <div>
            <p>
              ZEP <b>reduziert</b> bei unseren Kunden <b>die durchschnittliche Arbeitszeit pro Zeugnis</b> bis zu 1.5 Stunden.
              Dies ergibt abhängig von der Zeugnisanzahl für ZEP-Projekte einen ROI von einem halben Jahr.
          </p>
            <p>Den Personalabteilungen stehen verschiedene Auswertungen zur Verfügung, wie beispielsweise das Messen der Durchlaufszeiten und das Beurteilen der Termintreue.</p>
            <p className="home-link"><Link to="/zep-info/prozess">Erfahren Sie mehr</Link></p>
          </div>
        </div>

        <div className="home-section col-md-12 col-lg-6">
          <div className="home-section-title">
            <div className="icon">
              <FontAwesomeIcon icon={faLink} size="3x" />
            </div>
            <div>
              <h1 className="underlined-title">Integration</h1>
              <h5>Vollständige Integration in bestehende Umgebungen</h5>
            </div>
          </div>
          <div>
            <p>
              ZEP ist <b>integrierbar in Ihr bestehendes HR Portal</b>. Ihr Mitarbeiterstamm, Ihre Organisations- und Funktionsdaten können von der ZEP-Schnittstelle
              in den Zeugnisprozess eingebunden werden. SingleSignOn ist in ZEP implementiert.
            </p>
            <p className="home-link"><Link to="/zep-info/technik">Erfahren Sie mehr</Link></p>
          </div>
        </div>

        <div className="home-section col-md-12 col-lg-6">
          <div className="home-section-title small" >
            <div className="icon">
              <FontAwesomeIcon icon={faHandshake} size="3x" />
            </div>
            <div>
              <h1 className="underlined-title">Referenzen</h1>
            </div>
          </div>
          <div>
            <p>
              "Mit der Einführung des Zeugnisprozesses, konnten wir die Bearbeitungszeit pro Arbeitszeugnis um 50% senken und gleichzeitig die Qualität des Inhalts markant steigern."
            </p>
            <p>Thomas Renner, Head HR Switzerland, Swiss Life AG, Zürich</p>
            <p className="home-link"><Link to="/zep-referenzen">Weitere Referenzen</Link></p>
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default IndexPage
