import React, { Component, Children } from "react"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

import './carousel.css'

class Carousel extends Component {

    state = {
        activePane: 0,
        itemCount: 0
    }

    incrementActivePane = () => {
        if (this.state.activePane === this.state.itemCount - 1) {
            this.setState({
                activePane: 0
            })
        } else {
            this.setState(prevState => ({
                activePane: prevState.activePane + 1
            }))
        }
    }

    decrementActivePane = () => {
        if (this.state.activePane === 0) {
            this.setState(prevState => ({
                activePane: prevState.itemCount - 1
            }))
        }
        else {
            this.setState(prevState => ({
                activePane: prevState.activePane - 1
            }))
        }
    }

    componentDidMount = () => {
        this.setState({ itemCount: this.props.children.length });
    }

    render() {

        return (
            <div id="carousel">
                {
                    this.state.itemCount > 1 ?
                        <span className="prev" onClick={this.decrementActivePane}><FontAwesomeIcon icon={faChevronLeft} size="2x" /></span>
                        : null
                }
                {Children.map(this.props.children, (item, index) => {
                    return (
                        <div className={index === this.state.activePane ? 'item active' : 'item'}>
                            <div className="overlay" />
                            <div className="image" style={{ backgroundImage: "url(" + item.props.children[0] + ")" }} />
                            {item.props.children[1]}
                            {item.props.children[2]}
                            {item.props.children[3]}
                        </div>
                    )
                })}
                {
                    this.state.itemCount > 1 ?
                        <span className="next" onClick={this.incrementActivePane}><FontAwesomeIcon icon={faChevronRight} size="2x" /></span>
                        : null
                }
            </div>
        )
    }
}

export default Carousel



